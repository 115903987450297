.conf_button{
    background-color: #16BA5A;
    text-transform: uppercase;
    position: fixed;
    bottom: 0px;
    width: 80%;
    min-height: 10vw;
    color: white;
    box-shadow: 0px 0px 3vw 0.2vw rgba(0, 0, 0, 0.4);
    border: none;
    background: linear-gradient(45deg, #16BA5A, #81e9ac);
    background-size: 200% 200%;
    animation: gradient 5s ease infinite;
    z-index: 7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4vw;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}





@media (max-width: 10000px){
    .conf_button{
        background-color: #16BA5A;
        text-transform: uppercase;
        position: fixed;
        bottom: 0px;
        width: 80%;
        min-height: 6vw;
        color: white;
        box-shadow: 0px 0px 3vw 0.2vw rgba(0, 0, 0, 0.4);
        border: none;
        background: linear-gradient(45deg, #16BA5A, #81e9ac);
        background-size: 200% 200%;
        animation: gradient 5s ease infinite;
        z-index: 7;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2vw;
    }
    
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
    
        50% {
            background-position: 100% 50%;
        }
    
        100% {
            background-position: 0% 50%;
        }
    }
}

@media (max-width: 700px){
    .conf_button{
        background-color: #16BA5A;
        text-transform: uppercase;
        position: fixed;
        bottom: 0px;
        width: 100%;
        min-height: 10vw;
        color: white;
        box-shadow: 0px 0px 3vw 0.2vw rgba(0, 0, 0, 0.4);
        border: none;
        background: linear-gradient(45deg, #16BA5A, #81e9ac);
        background-size: 200% 200%;
        animation: gradient 5s ease infinite;
        z-index: 7;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 4vw;
    }
    
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
    
        50% {
            background-position: 100% 50%;
        }
    
        100% {
            background-position: 0% 50%;
        }
    }
}
