footer{
    padding: 1.37vw 0;
    background-color: #3f3f3f;
    margin-bottom: 4vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

footer a img{
    width: 1.2vw;
}

.footer_right{
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width: 30%;
}

.footer_right p{
    color: white;
    text-align: left;
    font-size: 1vw;
    margin-top: 0px;
}

.footer_right img{
    width: 4.3vw;
}

.footer_left{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 0.68vw;
}

.footer_left a{
    gap: 0.4vw;
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1vw;
}



@media (max-width: 700px){
    footer{
        padding: 4vw 0;
        background-color: #3f3f3f;
        margin-bottom: 10vw;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    
    footer a img{
        width: 3.7vw;
    }
    
    .footer_right{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 30%;
    }
    
    .footer_right p{
        color: white;
        text-align: center;
        font-size: 2.5vw;
        margin-top: 0px;
    }
    
    .footer_right img{
        width: 15vw;
    }
    
    .footer_left{
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 2vw;
    }
    
    .footer_left a{
        gap: 2vw;
        color: white;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 2.5vw;
    }
}