header {
    background-color: #3d3d3d;
    padding: 5px 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: calc(100% - 10%);
    height: 60px;
    top: 0;
    z-index: 4;
    transition: 0.5s;
}

header a {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

header img {
    width: 55px;
}

header a img {
    width: 18px;
}

.scrolled{
    background-color: transparent;
    backdrop-filter: blur(4px);
}

.scrolled a{
    color: #404040;
}

.scrolled img{
    filter: contrast(0) brightness(0.5);
}




@media (max-width: 10000px){
    header {
        background-color: #3d3d3d;
        padding: 1vw 5%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        width: calc(60% - 10%);
        height: 6vw;
        top: 0;
        z-index: 4;
        transition: 0.5s;
    }
    
    header a {
        text-decoration: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.2vw;
    }
    
    header img {
        width: 5vw;
    }
    
    header a img {
        width: 1.3vw;
    }

    a{
        font-size: 1.1vw;
    }
    
    .scrolled{
        background-color: transparent;
        backdrop-filter: blur(0.8vw);
    }
    
    .scrolled a{
        color: #404040;
    }
    
    .scrolled img{
        filter: contrast(0) brightness(0.5);
    }
}

@media (max-width: 700px){
    header {
        background-color: #3d3d3d;
        padding: 1vw 5%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        width: calc(100%);
        height: 15vw;
        top: 0;
        z-index: 4;
        transition: 0.5s;
    }
    
    header a {
        text-decoration: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1vw;
    }
    
    header img {
        width: 13vw;
    }
    
    header a img {
        width: 3.6vw;
    }

    a{
        font-size: 3.2vw;
    }
    
    .scrolled{
        background-color: transparent;
        backdrop-filter: blur(0.8vw);
    }
    
    .scrolled a{
        color: #404040;
    }
    
    .scrolled img{
        filter: contrast(0) brightness(0.5);
    }
}


