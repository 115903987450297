@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,100..900;1,100..900&display=swap');

.bracket2 {
    font-size: 1.5vw;
    font-weight: normal;
    color: #0431FA;
}

.bl {
    color: #2f2f2f;
}

.timer_inner {
    text-align: center;
    margin: 2vw 2vw;
    font-size: 1.2vw;
}


.banner {
    display: block;
    position: relative;
    margin-top: 6vw;
    height: 35vw;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: bottom left;
}

.banner:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .15);
    z-index: 2;
}

.banner img {
    position: absolute;
    filter: drop-shadow(0px 0px 4vw #00000060);
    z-index: 3;
}



.image_main {
    width: 22.5vw;
    top: calc(50% - 6vw);
    left: calc(50% - 11.25vw);
}

.image_one {
    width: 6vw;
    top: 22%;
    left: 14%;
}

.image_two {
    width: 9vw;
    bottom: 10%;
    left: 14%;
}

.image_three {
    width: 10vw;
    top: 5%;
    right: calc(50% - 5vw);
}

.image_four {
    width: 8vw;
    top: 25%;
    right: 11%;
}

.image_five {
    width: 9vw;
    bottom: 10%;
    right: 15%;
}

.banner span {
    color: #3f3f3f;
    font-size: 8vw;
    font-weight: 500;
    position: absolute;
    width: 100%;
    bottom: -5vw;
    text-align: center;
    z-index: 3;
}

.timer {
    font-size: 4.5vw;
    margin-top: 0;
    color: #098658;
    width: 100%;
    text-align: center;
    display: block;
    position: relative;
    margin-bottom: 0;
    font-family: 'Source Code Pro', monospace;
}

.desc_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.desc {
    color: #3f3f3f;
    font-size: 2.4vw;
    width: 85%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.timer .bracket {
    color: #0431FA;
}

.day {
    position: relative;
    padding: 0px 1vw;
}

.hour {
    position: relative;
    padding: 0px 1vw;
}

.minute {
    position: relative;
    padding: 0px 1vw;
}

.second {
    position: relative;
    padding: 0px 1vw;
}

.day::after {
    content: "дней";
    color: #3f3f3f;
    font-size: 1.5vw;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
}

.hour::after {
    content: "часов";
    color: #3f3f3f;
    font-size: 1.5vw;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
}

.minute::after {
    content: "минут";
    color: #3f3f3f;
    font-size: 1.5vw;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
}

.second::after {
    content: "секунд";
    color: #3f3f3f;
    font-size: 1.5vw;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
}

.timer_head {
    color: #098658;
    font-family: 'Source Code Pro', monospace;
    margin-top: 4vw;
    margin-bottom: 1vw;
    width: 100%;
    text-align: center;
    font-size: 2.5vw;
}

.timer_foot {
    color: #A31515;
    font-family: 'Source Code Pro', monospace;
    margin-top: 3vw;
    margin-bottom: 1vw;
    width: 100%;
    text-align: center;
    font-size: 1.3vw;
    font-weight: 100;
    position: relative;
}

.timer_foot::after {
    font-size: 1.3vw;
    color: black;
    content: '|';
    margin-left: -0.2vw;
    animation: 1s step-end infinite cur;
}

.content {
    color: #2f2f2f;
    background-color: white;
    margin: 0px 3% 10px 3%;
    padding: 2%;
    border-radius: 10px;
    box-shadow: 0px 0px 2vw 0.2vw rgba(0, 0, 0, 0.1);
}

.row {
    font-family: 'Source Code Pro', monospace;
    padding-left: 6vw;
    line-height: 1.75;
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 2vw;
}

.number {
    color: #098658;
}

.value {
    color: #A31515;
}

.dict {
    font-family: 'Source Code Pro', monospace;
    font-weight: 500;
    font-size: 1.5vw;
    margin-bottom: 2vw;
    margin-top: 0;
}

.plan {
    color: #2f2f2f;
    background-color: white;
    margin: 2vw 3%;
    padding: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 2vw 0.2vw rgba(0, 0, 0, 0.1);
}

.plan_title span {
    padding: 0.8vw 1vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

}

.dict_obj {
    padding: 2vw;
}

.plan_title {
    border-bottom: 2px solid lightgray;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.plan_title span img {
    height: 2vw;
}

.plan_button {
    margin-left: 3vw;
    display: block;
    background-color: #16BA5A;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 0 0.8vw;
    text-decoration: none;
    color: white;
    font-size: 1vw;
}

.usage {
    margin: 2vw 2%;
    padding: 2vw 2vw 2vw 6vw;
    border-radius: 2vw;
    display: flex;
    align-items: center;
    min-height: 8vw;
    position: relative;
    z-index: 0;
    overflow: hidden;
}

.usage img {
    position: absolute;
    left: -4vw;
    width: 18vw;
    z-index: -1;
}

.list{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 2vw 2vw 2vw;
}

.list_head{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vw;
}

.list_head::before{
    display: flex;
    content: '-';
    border: 0.1vw solid black;
    width: 1vw;
    height: 1vw;
    align-items: center;
    justify-content: center;
}

.list_inner{
    border-left: 2px solid gray;
    margin-left: 2.6vw;
    transform: translate(0, -0.6vw);

}
.list_inner div{
    display: flex;
    flex-direction: row;
    transform: translate(-0.08vw, 2.15vw);
    padding: 1.6vw 0;
}
.list_inner div::before{
    display: block;
    content:'— ';
    margin-right: 0.6vw;
    color: gray;
}



@keyframes cur {

    from,
    to {
        color: transparent;
    }

    50% {
        color: black;
    }
}


@media(max-width: 700px){
    .bracket2 {
        font-size: 3.6vw;
        font-weight: normal;
        color: #0431FA;
    }
    
    .bl {
        color: #2f2f2f;
    }
    
    .timer_inner {
        text-align: center;
        margin: 4vw 2vw;
        font-size: 3.5vw;
    }
    
    
    .banner {
        display: block;
        position: relative;
        margin-top: 14vw;
        height: 100vw;
        background-repeat: no-repeat;
        background-size: 150%;
        background-position: bottom left;
    }
    
    .banner:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .15);
        z-index: 2;
    }
    
    .banner img {
        position: absolute;
        filter: drop-shadow(0px 0px 4vw #00000060);
        z-index: 3;
    }
    
    
    
    .image_main {
        width: 88vw;
        top: calc(50% - 24.8vw);
        left: calc(50% - 44vw);
    }
    
    .image_one {
        width: 16vw;
        top: 20%;
        left: 5%;
    }
    
    .image_two {
        width: 24vw;
        bottom: 10%;
        left: 5%;
    }
    
    .image_three {
        width: 32vw;
        top: 3%;
        right: calc(50% - 60px);
    }
    
    .image_four {
        width: 20vw;
        top: 22%;
        right: 5%;
    }
    
    .image_five {
        width: 24vw;
        bottom: 10%;
        right: 8%;
    }
    
    .banner span {
        color: #3f3f3f;
        font-size: 26vw;
        font-weight: 500;
        position: absolute;
        width: 100%;
        bottom: -16vw;
        text-align: center;
        z-index: 3;
    }
    
    .timer {
        font-size: 9vw;
        margin-top: 0;
        color: #098658;
        width: 100%;
        text-align: center;
        display: block;
        position: relative;
        margin-bottom: 0;
        font-family: 'Source Code Pro', monospace;
    }
    
    .desc_header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .desc {
        color: #3f3f3f;
        font-size: 2.4vw;
        width: 85%;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    
    .timer .bracket {
        color: #0431FA;
    }
    
    .day {
        position: relative;
        padding: 0px 2.667vw;
    }
    
    .hour {
        position: relative;
        padding: 0px 2.667vw;
    }
    
    .minute {
        position: relative;
        padding: 0px 2.667vw;
    }
    
    .second {
        position: relative;
        padding: 0px 2.667vw;
    }
    
    .day::after {
        content: "дней";
        color: #3f3f3f;
        font-size: 3.2vw;
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
    }
    
    .hour::after {
        content: "часов";
        color: #3f3f3f;
        font-size: 3.2vw;
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
    }
    
    .minute::after {
        content: "минут";
        color: #3f3f3f;
        font-size: 3.2vw;
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
    }
    
    .second::after {
        content: "секунд";
        color: #3f3f3f;
        font-size: 3.2vw;
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
    }
    
    .timer_head {
        color: #098658;
        font-family: 'Source Code Pro', monospace;
        margin-top: 11vw;
        margin-bottom: 1vw;
        width: 100%;
        text-align: center;
        font-size: 4.8vw;
    }
    
    .timer_foot {
        color: #A31515;
        font-family: 'Source Code Pro', monospace;
        margin-top: 5vw;
        margin-bottom: 5vw;
        width: 100%;
        text-align: center;
        font-size: 4.2vw;
        font-weight: 100;
        position: relative;
    }
    
    .timer_foot::after {
        font-size: 5.333vw;
        color: black;
        content: '|';
        margin-left: -0.8vw;
        animation: 1s step-end infinite cur;
    }
    
    .content {
        color: #2f2f2f;
        background-color: white;
        margin: 10px 3%;
        padding: 2%;
        border-radius: 10px;
        box-shadow: 0px 0px 2vw 0.2vw rgba(0, 0, 0, 0.1);
    }
    
    .row {
        font-family: 'Source Code Pro', monospace;
        padding-left: 6vw;
        line-height: 1.75;
        display: flex;
        flex-direction: row;
        align-items: start;
        gap: 2vw;
    }
    
    .number {
        color: #098658;
    }
    
    .value {
        color: #A31515;
    }
    
    .dict {
        font-family: 'Source Code Pro', monospace;
        font-weight: 500;
        font-size: 5vw;
        margin-bottom: 2vw;
        margin-top: 0;
    }
    
    .plan {
        color: #2f2f2f;
        background-color: white;
        margin: 4vw 3%;
        padding: 0;
        border-radius: 10px;
        box-shadow: 0px 0px 2vw 0.2vw rgba(0, 0, 0, 0.1);
    }
    
    .plan_title span {
        padding: 1vw 3vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
    
    }
    
    .dict_obj {
        padding: 3vw;
    }
    
    .plan_title {
        border-bottom: 0.2vw solid lightgray;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 4vw;
        padding: 1vw;
    }
    
    .plan_title span img {
        height: 6.5vw;
    }
    
    .plan_button {
        margin-left: 3vw;
        display: block;
        background-color: #16BA5A;
        padding: 1vw 2vw;
        border-radius: 1vw;
        margin: 1vw;
        text-decoration: none;
        color: white;
        font-size: 4vw;
    }
    
    .usage {
        margin: 2vw 2%;
        padding: 2vw 2vw 2vw 6vw;
        border-radius: 2vw;
        display: flex;
        align-items: center;
        min-height: 8vw;
        position: relative;
        z-index: 0;
        overflow: hidden;
    }
    
    .usage img {
        position: absolute;
        left: -4vw;
        width: 18vw;
        z-index: -1;
    }
    
    .list{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 0 2vw 4vw 2vw;
    }
    
    .list_head{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1vw
    }
    
    .list_head::before{
        display: flex;
        content: '-';
        border: 0.2vw solid black;
        width: 2vw;
        height: 2vw;
        align-items: center;
        justify-content: center;
    }
    
    .list_inner{
        border-left: 0.4vw solid gray;
        margin-left: 4.8vw;
        transform: translate(0px, -1.8vw);
    
    }
    .list_inner div{
        display: flex;
        flex-direction: row;
        transform: translate(-0.2vw, 3.66vw);
        padding: 2vw 0;
    }
    .list_inner div::before{
        display: block;
        content:'— ';
        margin-right: 0.6vw;
        color: gray;
    }
    
    
    
    @keyframes cur {
    
        from,
        to {
            color: transparent;
        }
    
        50% {
            color: black;
        }
    }
}