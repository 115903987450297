@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


body {
    margin: 0;
    font-family: "Inter", sans-serif;
    background-color: #d8d8d8;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* margin-left: auto;
    margin-right: auto; */
}



@media (min-width: 701px){
    .App{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}


