
body{
  position: relative;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.form {
  position: fixed;
  width: 40%;
  height: 40vw;
  background: #F1F1F1E1;
  display: flex;
  flex-direction: column;
  gap: 0.54vw;
  color: #252525;
  border-radius: 1.23vw;
  padding: 1.7vw;
  align-items: center;
  z-index: 5;
  box-shadow: 0px 0px 1.37vw 0.06vw rgba(0, 0, 0, 0.1);
}

.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}




.background{
background-color: #252525A5;
backdrop-filter: blur(0.34vw);
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

.icon_remove {
position: absolute;
font-size: 1.9vw;
right: 0.68vw;
top: 0.68vw;
cursor: pointer;
}

.form h1 {
font-size: 2.88vw;
margin-top: 0.68vw;
}

.form p {
font-size: 1.23vw;
}
.form h3 {
font-size: 1.23vw;
}

.form_input {
display: flex;
flex-direction: column;
width: 100%;
height: 70%;
gap: 1vw;
align-items: center;
justify-content: center;
}

.form_input input {
width: 70%;
height: 3.5vw;
border-radius: 1.37vw;
padding: 0.54vw 1vw;
font-size: 1vw;
border: none;
}

.form button {
width: 75%;
height: 15%;
border-radius: 1.37vw;
border: none;
background-color: #16BA5A;
color: white;
font-size: 1.16vw;
margin-bottom: 1vw;
transition: 0.3s;
cursor: pointer;
}

.form button:hover{
filter: brightness(1.2);
transform: scale(1.05);
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}

input[type="number"] {
-moz-appearance: textfield;
}

select {
width: 65%;
height: 14%;
border-radius: 0.4vw;
padding: 0.4vw;
font-size: 1vw;
}

option {
border-radius: 0.6vw;
}

.disable {
display: none;
}



.conf_button{
  background-color: #16BA5A;
  text-transform: uppercase;
  position: fixed;
  bottom: 0px;
  width: 80%;
  min-height: 10vw;
  color: white;
  box-shadow: 0px 0px 3vw 0.2vw rgba(0, 0, 0, 0.4);
  border: none;
  background: linear-gradient(45deg, #16BA5A, #81e9ac);
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4vw;
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }

  50% {
      background-position: 100% 50%;
  }

  100% {
      background-position: 0% 50%;
  }
}







@media (max-width: 10000px){
  .conf_button{
      background-color: #16BA5A;
      text-transform: uppercase;
      position: fixed;
      bottom: 0px;
      width: 50%;
      min-height: 4vw;
      color: white;
      box-shadow: 0px 0px 3vw 0.2vw rgba(0, 0, 0, 0.4);
      border: none;
      background: linear-gradient(45deg, #16BA5A, #81e9ac);
      background-size: 200% 200%;
      animation: gradient 5s ease infinite;
      z-index: 7;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.3vw;
  }
  
  @keyframes gradient {
      0% {
          background-position: 0% 50%;
      }
  
      50% {
          background-position: 100% 50%;
      }
  
      100% {
          background-position: 0% 50%;
      }
  }
}



@media (max-width: 768px) {

  .form{
    width: 90%;
    height: 120vw;
    gap: 1vw;
    padding: 3vw;
    padding-top: 8vw;
  }

 

  .icon_remove{
    font-size: 5vw;
  }


.form_input{
  height: 55%;
  gap: 3.2vw;
}
  .form_input input{
    width: 95%;
    height: 8vw;
    font-size: 3.5vw;
  }

  .form button{
    width: 90%;
    height: 10vw;
    font-size: 3.5vw;
    margin-top: 1vw;
  }

  .form h1{
    font-size: 7vw;
  }
  .form p{
    font-size: 4.5vw;
    text-align: center;
  }
  .form h3{
    font-size: 4vw;
    text-align: center;
  }

  

}

@media (max-width: 700px){
  .conf_button{
      background-color: #16BA5A;
      text-transform: uppercase;
      position: fixed;
      bottom: 0px;
      width: 100%;
      min-height: 10vw;
      color: white;
      box-shadow: 0px 0px 3vw 0.2vw rgba(0, 0, 0, 0.4);
      border: none;
      background: linear-gradient(45deg, #16BA5A, #81e9ac);
      background-size: 200% 200%;
      animation: gradient 5s ease infinite;
      z-index: 7;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3vw;
  }
  
  @keyframes gradient {
      0% {
          background-position: 0% 50%;
      }
  
      50% {
          background-position: 100% 50%;
      }
  
      100% {
          background-position: 0% 50%;
      }
  }
}


